import React, { useEffect, useState } from "react";
import DeviceList from "../components/DeviceList";
import ChartComponent from "../components/ChartComponent";
import ChartComponent2 from "../components/ChartComponent2";
import ChartComponentW1 from "../components/ChartComponentW1";
import ChartComponentW2 from "../components/ChartComponentW2";
import ChartComponentM1 from "../components/ChartComponentM1";
import ChartComponentM2 from "../components/ChartComponentM2";
import { Chart, registerables } from "chart.js";
import MainContents from "../components/MainContents";
import "../styles/Dashboard.css";
import { useQuery } from "@tanstack/react-query";
import ApiClient, { api_method } from "../utils/ApiClient";
import Practice from "../practice";
Chart.register(...registerables);
const client = new ApiClient();
function Dashboard() {
  const [selectedInterval1, setSelectedInterval1] = useState("day");
  const [selectedInterval2, setSelectedInterval2] = useState("day");
  const [deviceType, setDeviceType] = useState([]);
  const [deviceName, setDeviceName] = useState([]);
  const token = sessionStorage.getItem("authorizeKey");
  const [deviceIdList, setDeviceIdList] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
  const [selectedDevice, setSelectedDevice] = useState(0);

  const deviceTypeData1 = async () => {
    try {
      const response = await client.RequestAsync(
        api_method.get,
        `/api/acqdata/count?device_id=55&acq_type=E&count=200`,
        null,
        null,
        token
      );
      const data = response.data;
      return data; // 데이터를 반환해야 함
    } catch (error) {
      console.error(error);
      return []; // 실패 시 기본값을 반환
    }
  };
  useEffect(() => {
    if (deviceIdList.length > 0) {
      setSelectedDevice(deviceIdList[0]); // deviceIdList가 업데이트되면 첫 번째 값을 선택
    }
  }, [deviceIdList]); // deviceIdList가 변경될 때 실행

  useEffect(() => {
    // 페이지가 마운트될 때 4초 후 로딩 상태를 false로 변경
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    return () => clearTimeout(timer); // 타이머 정리
  }, []);

  useEffect(() => {
    const deviceTypeData = async () => {
      try {
        const response = await client.RequestAsync(
          api_method.get,
          "/api/config/device/info",
          null,
          null,
          token
        );
        const data = response.data;
        const newDeviceIdList = data.map((value, index) => value.device_id);
        const newDeviceNameList = data.map((value, index) => value.device_name);

        if (JSON.stringify(newDeviceIdList) !== JSON.stringify(deviceIdList)) {
          setDeviceIdList(newDeviceIdList);
        }
        if (JSON.stringify(newDeviceNameList) !== JSON.stringify(deviceName)) {
          setDeviceName(newDeviceNameList);
        }
      } catch (error) {
        console.error(error);
      }
    };

    deviceTypeData();
  }, [token, deviceIdList, deviceName]);
  console.log()
  useEffect(() => {}, [deviceType]);

  useEffect(() => {}, [deviceName]);

  const handleIntervalChange1 = (interval) => {
    setSelectedInterval1(interval);
  };

  const handleIntervalChange2 = (interval) => {
    setSelectedInterval2(interval);
  };
  const handleDeviceChange = (deviceId) => {
    setSelectedDevice(deviceId);
  };

  
  return (
    <div className="Main">
      <Practice />
      <DeviceList deviceType={deviceType} />
      <MainContents deviceId={deviceIdList} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "26px",
          gap: "30px",
        }}
      >
        <div
          style={{
            width: 782,
            height: 292,
            background: "#fcfcfc",
            textAlign: "center",
            alignItems: "center",
            borderRadius:"30px"
          }}
        >
          <div className="Chart-Title">
            <div className="Title">위험(경고) 누적 그래프</div>
            <div className="Chart-Btn1">
              <button
                className={`Chart-Btn-btn1 ${
                  selectedInterval1 === "day" ? "selected" : ""
                }`}
                onClick={() => handleIntervalChange1("day")}
              >
                일
              </button>
              <button
                className={`Chart-Btn-btn1 ${
                  selectedInterval1 === "week" ? "selected" : ""
                }`}
                onClick={() => handleIntervalChange1("week")}
              >
                주
              </button>
              <button
                className={`Chart-Btn-btn1 ${
                  selectedInterval1 === "month" ? "selected" : ""
                }`}
                onClick={() => handleIntervalChange1("month")}
              >
                월
              </button>
            </div>
          </div>
          {selectedInterval1 === "day" && (
            <ChartComponent deviceId={deviceIdList} deviceType={deviceType} />
          )}
          {selectedInterval1 === "week" && (
            <ChartComponentW1 deviceId={deviceIdList} deviceType={deviceType} />
          )}
          {selectedInterval1 === "month" && (
            <ChartComponentM1 deviceId={deviceIdList} deviceType={deviceType} />
          )}
        </div>
        <div
          style={{
            width: 789,
            height: 292,
            borderRadius: "30px",
            background: "#fcfcfc",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <div className="Chart-Title">
            <div className="Title">심박수ㆍ호흡수 추이 그래프</div>

            <div style={{ margin: "0px 51px 0px 0px" }}>
              <select
                className="chart-dropDown"
                value={selectedDevice}
                onChange={(e) => handleDeviceChange(Number(e.target.value))}
              >
                {deviceIdList.map((deviceId, index) => (
                  <option key={deviceId} value={deviceId}>
                    {deviceName[index]}
                  </option>
                ))}
              </select>
            </div>

            <div className="Chart-Btn2">
              <button
                className={`Chart-Btn-btn2 ${
                  selectedInterval2 === "day" ? "selected" : ""
                }`}
                onClick={() => handleIntervalChange2("day")}
              >
                일
              </button>
              <button
                className={`Chart-Btn-btn2 ${
                  selectedInterval2 === "week" ? "selected" : ""
                }`}
                onClick={() => handleIntervalChange2("week")}
              >
                주
              </button>
              <button
                className={`Chart-Btn-btn2 ${
                  selectedInterval2 === "month" ? "selected" : ""
                }`}
                onClick={() => handleIntervalChange2("month")}
              >
                월
              </button>
            </div>
          </div>
          {selectedInterval2 === "day" && (
            <ChartComponent2
              deviceId={deviceIdList}
              dropDown={selectedDevice}
            />
          )}
          {selectedInterval2 === "week" && (
            <ChartComponentW2
              deviceId={deviceIdList}
              dropDown={selectedDevice}
            />
          )}
          {selectedInterval2 === "month" && (
            <ChartComponentM2
              deviceId={deviceIdList}
              dropDown={selectedDevice}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
