import axios from "axios";
import { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";

const Practice = () => {
  const token = sessionStorage.getItem("authorizeKey");
  const [messages, setMessages] = useState([]);
  const [scanWifi, setScanWifi] = useState([]);
  const ssid = "Bullseye5G";
  const password = "bullseye@_@";
  // useEffect(() => {
  //   const connection = new signalR.HubConnectionBuilder()
  //     .withUrl("http://api.bullseye.co.kr:7811/alarmHub") // SignalR hub URL
  //     .build();

  //   connection
  //     .start()
  //     // .then(() => console.log('SignalR 연결 성공'))
  //     .catch((err) => console.error("SignalR 연결 실패", err));

  //   connection.on("ReceiveMessage", (user, message) => {
  //     setMessages((prevMessages) => [...prevMessages, { user, message }]);
  //   });

  //   return () => {
  //     connection.stop();
  //   };
  // }, []);
  // Signal R 메세지 호출
  // console.log(messages)

  // 네트워크 스캔 API 호출
  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get("http://localhost:8080/api/scan");
  //     const ssidList = response.data
  //     console.log(ssidList)
  //     setScanWifi(ssidList); // 여기서 데이터를 출력
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);

  // console.log(scanWifi);


  // 네트워크 연결 API POST  .
  
  // const networkConnect = async () => {
  //   const data = { ssid, password };
  //   console.log(data);
  //   try {
  //     const response = await axios.post(
  //       "http://localhost:8080/api/connect",
  //       data,
  //       {
  //         headers: { "Content-Type": "application/json" },
  //         withCredentials: true,
  //       }
  //     );
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  // useEffect(() => {
  //   networkConnect();
  // }, []);

  // const networkCurrentConnect = async () => {
  //   try {
  //     const response = await axios.get("http://localhost:8080/api/current-connection");
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  // useEffect(() => {
  //   networkCurrentConnect();
  // }, []);


  // axios.post("http://localhost:8080/api/scan").then((res)=>{console.log(res)}).catch((err)=>{console.log(err)})
  // axios.get("http://localhost:8080/api/current-connection").then((res)=>{console.log(res)}).catch((err)=>{console.log(err)})
};
export default Practice;
